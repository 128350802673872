
import { useEffect, useState } from "react";
import strapi_fetch from "../services/Strapi";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { Colors } from "../services/Colors";
import { ArrowBack, Replay } from "@mui/icons-material";

export default function Cotations(){
    const [loading, setLoading] = useState(true);
    const [etapes, setEtapes] = useState([]);
    const [numEtape, setNumEtape] = useState(undefined);
    const [etape, setEtape] = useState(undefined);
    const [listeChoix, setListeChoix] = useState([]);

    useEffect(() => {
        async function funcAsync(){
            let page = 1
            let metaPagination = {}
            let etapes_temp = {}
            do{
                const res = await strapi_fetch("cotations-etapes?populate[0]=Choix_question.Image&pagination[pageSize]=100&pagination[page]="+page,{})
                if(res.json.error){
                    console.log(res.json);
                    return
                }
                metaPagination = res.json.meta.pagination
                let data = res.json.data
                data = data.map(et=>{
                    let { attributes } = et;
                    attributes = {...attributes} 
                    delete attributes.createdAt
                    delete attributes.updatedAt
                    delete attributes.publishedAt
                    attributes.Choix_question = attributes.Choix_question.map(ch=>{
                        ch.Image={
                            name : ch.Image.data.attributes.name,
                            hash : ch.Image.data.attributes.hash,
                            url : ch.Image.data.attributes.url
                        }
                        return ch
                    })
                    etapes_temp[attributes.Numero] = attributes
                    return {...attributes}
                })

                page++
            }while(metaPagination.page != metaPagination.pageCount);

            setEtapes(etapes_temp)
            setLoading(false)
            handleRecommencer();
        }
        funcAsync()
    }, []);

    useEffect(() => {
        let temp = Object.entries(etapes).find(([key, value]) => key == numEtape)
        setEtape(temp===undefined?undefined:temp[1]) 
    }, [numEtape]);

    const handlePress = (choix)=>{
        setNumEtape(choix.Etape_suivante)
        setListeChoix(prev=>{
            return [...prev, { [numEtape]: choix.Image.url }]
        }) 
    }

    const handleBack = ()=>{
        if(listeChoix.length==0) return 

        setListeChoix(prev=>{
            setNumEtape(Object.keys(prev[prev.length-1])[0])
            prev.pop()
            return prev
        })
    }

    const handleRecommencer = ()=>{ 
        setNumEtape(1)
        setListeChoix([])
        /*setSlidedDown(false)
        animatedvalue.setValue(maxHeightResult);
        setFirstLayout(true)*/
    }

    return(
        <>
            {loading && <Box sx={{textAlign:"center", my:4}}><CircularProgress/></Box>}
            {!loading && listeChoix.length>0 && 
                <Box sx={{position:"relative"}}>
                    <Box sx={{display:{xs:"block", sm:"none"}, position:"absolute", top:0, left:0}}>
                        <IconButton sx={{color:Colors.darkerBlue}} onClick={handleBack}>
                            <ArrowBack sx={{width:"35px", height:"35px"}}/>
                        </IconButton>
                    </Box>
                    <Box sx={{fontSize:25, textAlign:"center", color:Colors.darkBlue}}>Mes critères</Box>
                    <Box sx={{position:"relative", width:"fit-content", margin:"auto"}}>
                        <Box sx={{display:"flex", justifyContent: 'center', alignItems: 'center',  }}>
                            {listeChoix.map((o)=>Object.values(o)[0]).map((imageUrl,index) => (
                                <Box key={index} style={{ width: 80, height: 80, justifyContent: "center", alignItems: "center", backgroundImage:`url(https://strapi.alize-kine.org${imageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "contain" }}></Box>
                            ))}
                        </Box>
                        <Box sx={{display:{xs:"none", sm:"block"}, position:"absolute", top:0, left:"-100px"}}>
                            <IconButton sx={{color:Colors.darkerBlue}} onClick={handleBack}>
                                <ArrowBack sx={{width:"40px", height:"40px"}}/>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            }
            {!loading && etape?.Type==="Question" &&
                <>
                <Typography sx={{color:Colors.darkBlue, textTransform:"uppercase", textAlign:"center",fontSize:22, fontWeight:"600", pt:2}}>{etape.Titre}</Typography>
                <Box sx={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                    {etape.Choix_question.map(choix=>(
                        <Box sx={styles.cotationBouton} onClick={()=>handlePress(choix)}>
                            <Box sx={{...styles.image_cotationBouton, backgroundImage:`url(https://strapi.alize-kine.org${choix.Image.url})`}}></Box>
                            <Box sx={{p:1}}>{choix.Label}</Box>
                        </Box>
                    ))}
                </Box>
                </>
            }
            {!loading && etape?.Type==="Réponse" &&
                <Box sx={{mt:2}}>
                    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", marginHorizontal:60}}>
                        <Box sx={{fontSize:30, fontWeight:700, color:Colors.darkBlue, borderColor:Colors.darkBlue, border:"4px solid", borderRadius:"10px", padding:"4px", textAlign:"center", mx:2}}>{etape.Titre}</Box>
                        <Box sx={{width:40, height:40, transform:"rotate(45deg)", backgroundColor:Colors.darkBlue, justifyContent:"center", alignContent:"center"}}>
                            <Box style={{transform:"rotate(-45deg)", color:"white", fontWeight:900, fontSize:20, textAlign:"center"}}>{etape.Indemnite_Domicile}</Box>
                        </Box>
                        <IconButton sx={{color:Colors.darkerBlue, marginLeft:"40px"}} onClick={handleRecommencer}>
                            <Replay sx={{width:"40px", height:"40px"}}/>
                        </IconButton>
                    </Box>

                    <Box sx={{maxWidth:"1200px", width:"fit-content", margin:"auto", backgroundColor:"white", borderRadius:"10px", mt:1, mb:3, p:2, mx:"auto"}}>
                        <Box style={{fontSize:20, paddingHorizontal:8, textAlign:"center", fontWeight:600}}>{etape.Label}</Box>
                        {etape.Commentaire && <Box style={{fontSize:15, padding:8, textAlign:"center", borderRadius:10, fontStyle:"italic"}}>{etape.Commentaire}</Box>}
                    </Box>
                </Box>
            }
        </>
    )
}

const styles = {
    cotationBouton : {
        background:"linear-gradient(0deg, #4c718e, #112a48)", 
        flex:{xs:"0 0 calc(50% - 30px)",sm:"0 0 calc(25% - 50px)", md:"0 0 calc(16.66% - 50px)"},
        margin:{xs:"15px",sm:"25px"}, 
        textAlign:"center",
        color:"white", 
        borderRadius:"20px",
        cursor:"pointer"
    },
    image_cotationBouton:{
        margin:"10px",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center", 
        height:{xs:"calc(50vw - 50px)",sm:"calc(25vw - 70px)", md:"calc(15vw - 70px)"}

    }
}


/*<TouchableOpacity style={styles.buttonFacebookStyle} activeOpacity={0.5} onPress={()=>onPress(dataImage)}>
            <LinearGradient colors={["#4c718e","#112a48"]} style={{flexDirection:'column',alignItems: 'center',alignContent:"space-between",height:"100%",}}>
                <Image source={{uri:dataImage}} style={[styles.buttonImageIconStyle, {height:choix.Afficher_label?"60%":"95%", width:choix.Afficher_label?"60%":"95%"}]}/>
                {choix.Afficher_label && 
                    <View style={{flexDirection:"column", justifyContent:"center", height:"30%"}}>
                        <Text style={[styles.buttonTextStyle, {fontSize:choix.Taille_label?choix.Taille_label:18}]}>
                            {choix.Label}
                        </Text>
                    </View>
                }
            </LinearGradient>
        </TouchableOpacity>*/